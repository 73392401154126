.filter-button {
  color: #192530;
  padding: 0px;
  width: auto;
  height: auto;
  margin-bottom: 3px;
  cursor: pointer;
}

.filter-button:hover {
  color: rgb(179, 179, 179);
}

.wo-do-details-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.or-text {
  margin: 20px 8px 0 8px;
  font-weight: bold;
}

.filter-status {
  font-weight: 600;
}
.switch{
  cursor: pointer;
}