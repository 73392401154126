.search-bar {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 180px;
  padding: 6px 14px;
  border-radius: 4px;
  background: white;
  color: black;
  border: none;
}

// Carbon Telkomsel
:global(.app.carbon-site .sidebar) {
  background: linear-gradient(135deg, #e61919, #FE0015);
}

:global(.sidebar-minimized .app.carbon-site .sidebar .sidebar-minimizer:before ){
  background-color: #cb0011;
}

:global(.carbon-site .sidebar .nav-link.active) {
  color: #fff;
  background: #b42d2d;
}

:global(.carbon-site .sidebar .nav-link:hover) {
  color: #fff;
  background: #ff3f3f;
}

:global(.carbon-site .sidebar .nav-icon){
  color: #fff;
}

:global(.sidebar-minimized .app.carbon-site .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items ){
  background: linear-gradient(135deg, #e61919, #FE0015);
}

:global(.sidebar-minimized .app.carbon-site .sidebar .nav-item:hover > .nav-link) {
  background: #ff3f3f;
}

// Stark Industries
:global(.app.stark-site .sidebar) {
  background: linear-gradient(135deg, #1c406c, #2b63a4);
}

:global(.sidebar-minimized .app.stark-site .sidebar .sidebar-minimizer:before ){
  background-color: #103158
}

:global(.stark-site .sidebar .nav-link.active) {
  color: #fff;
  background: rgb(16, 85, 168);
}

:global(.stark-site .sidebar .nav-icon){
  color: #fff;
}

:global(.sidebar-minimized .app.stark-site .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items ){
  background: linear-gradient(135deg, #1c406c, #2b63a4);
}

:global(.stark-site .sidebar .nav-link:hover) {
  color: #fff;
  background: #468cdb;
}

:global(.sidebar-minimized .app.stark-site .sidebar .nav-item:hover > .nav-link) {
  background: #468cdb;
}