.truck-left {
  width: 100%;
  object-fit: contain;
}
.truck-right {
  width: 100%;
  object-fit: contain;
}
.truck-front {
  width: 100%;
  object-fit: contain;
}
.truck-back {
  width: 100%;
  object-fit: contain;
}
.truck-bottom {
  width: 100%;
  object-fit: contain;
  height: 300px;
}

.gap-image-y {
  margin-top: 1rem;
}
