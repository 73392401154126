.toggle-switch-wrapper {
  display: flex;
  align-items: center; /* Vertically center items */
  justify-content: flex-end; /* Push items to the right */
  margin-bottom: 1rem;
}

.toggle-button {
  &:global(.btn),
  &:global(.btn-secondary) {
    background-color: transparent;
    padding: 4.5px;
    border: 0;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
  }

   &:hover {
   background-color: #cecece;
  }
}