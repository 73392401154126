.add-image {
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 120px;
  height: 120px;
  cursor: pointer;
}
.image-item {
  width: 120px;
  height: 120px;
  border-radius: 5px;
}
.image-item-container {
  position: relative;
}
.image-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}
.image-close {
  position: absolute;
  top: 0;
  right: 0;
  background: red;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  width: 23px;
  height: 23px;
}
