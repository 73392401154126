.container {
  display: flex;
}
.images {
  width: 100%;
  display: flex;
  overflow: auto;
  gap: 5px;
  padding: 5px 5px 5px 0px;
}
.add-button {
  border: 1px dashed #ccc;
  min-height: 100px;
  min-width: 100px;
  max-width: 100px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  color: #ccc;
  cursor: pointer;
  display: flex;
  font-size: 20px;
}
.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: flex;
}
.img-onclick {
  cursor: pointer;
}
.img-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 200px;
}

.img-remover {
  position: absolute;
  right: -2.5px;
  top: -2.5px;
  width: 15px;
  height: 15px;
  background-color: red;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
