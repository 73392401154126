// Here you can add other styles

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.flex-column-20 {
  flex: 20%;
  max-width: 20%;
}

.flex-column-25 {
  flex: 25%;
  max-width: 25%;
}

.flex-column-30 {
  flex: 30%;
  max-width: 30%;
}

.flex-column-50 {
  flex: 50%;
  max-width: 50%;
}

.flex-column-60 {
  flex: 60%;
  max-width: 60%;
}

.flex-column-70 {
  flex: 70%;
  max-width: 70%;
}

.flex-column-75 {
  flex: 72%;
  max-width: 72%;
}

.flex-column-100 {
  flex: 100%;
  max-width: 100%;
}

.flex-column-temp-details {
  flex: 20.5%;
  max-width: 20.5%;
}

.dash-details-button {
  width: 250px;
  font-weight: bold;
}

.title-text-speedometer {
  display: block;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.title-text-temp {
  display: block;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  padding-left: 2%;
}

.title-text-tempRooms {
  display: block;
  text-align: left;
  font-size: 20px;
  font-weight: bold;
}

.title-text-ratings {
  display: block;
  text-align: center;
  font-size: 48px;
  font-weight: bold;
}

.title-text-details {
  display: block;
  text-align: center;
  font-size: 48px;
}

.title-text-progressbar {
  display: block;
  text-align: center;
  font-size: 18px;
}

.fleet-monitor-panel {
  flex: 22%;
  max-width: 22%;
}

.fleet-monitor-panel-title {
  font-size: 14px;
  font-weight: 500;
  color: white;
  margin: 5% 0 0 5%;
}

.fleet-monitor-panel-connected {
  display: block;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #20a8d8;
}

.fleet-monitor-panel-distance {
  display: block;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #ffc107;
}

.fleet-monitor-panel-alert {
  display: block;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  color: #f86c6b;
}

.fleet-date-panel-title {
  display: block;
  text-align: left;
  font-size: 14px;
  margin: 5% 0 0 5%;
}

.fleet-date-panel-text {
  display: block;
  text-align: left;
  font-size: 14px;
  margin-left: 2%;
}

.fleet-car-panel {
  flex: 32%;
  max-width: 32%;
}

.fleet-map-panel {
  flex: 67%;
  max-width: 67%;
}

.fleet-charts {
  padding: 5% 0 5% 0;
}

.fleet-charts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: baseline;
}

.fleet-charts-size {
  flex: 30%;
  max-width: 30%;
}

.fleet-details-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #ecf0f1;
  padding: 7% 7% 7% 7%;
}

.fleet-details-title {
  flex: 45%;
  max-width: 45%;
  font-weight: 500;
}

.fleet-details-carplate {
  flex: 55%;
  max-width: 55%;
  color: white;
  font-weight: 500;
  font-size: 14px;
}

.fleet-details-text {
  flex: 55%;
  max-width: 55%;
}

.fleet-details-btn-right {
  flex: 100%;
  text-align: right;
  padding: 0 7% 3% 0;
}

.fleet-mondtl-card {
  padding: 2% 5% 0 5%;
}

.fleet-mondtl-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fleet-mondtl-table {
  flex: 33%;
  max-width: 33%;
}

.fleet-mondtl-panel {
  flex: 23%;
  max-width: 23%;
}

.fleet-mondtl-panel-time {
  display: block;
  font-size: 18px;
  font-weight: 500;
}

.fleet-mondtl-obd-data {
  flex: 75%;
  max-width: 75%;
}

.fleet-mondtl-map {
  flex: 100%;
  width: 100%;
  margin: 0 -3% 0 -3%;
}

.report-dodetails-table {
  flex: 45%;
  max-width: 45%;
}

@media (max-width: 1000px) {
  .flex-column-25 {
    flex: 100%;
    max-width: 100%;
  }

  .flex-column-30 {
    flex: 95%;
    max-width: 95%;
  }

  .flex-column-50 {
    flex: 45%;
    max-width: 45%;
  }

  .flex-column-70 {
    flex: 95%;
    max-width: 95%;
  }

  .flex-column-75 {
    flex: 95%;
    max-width: 95%;
  }

  .flex-column-100 {
    flex: 95%;
    max-width: 95%;
  }

  .flex-column-temp-details {
    flex: 45%;
    max-width: 45%;
  }

  .fleet-monitor-panel {
    flex: 45%;
    max-width: 45%;
  }

  .fleet-mondtl-table {
    flex: 100%;
    max-width: 100%;
  }

  .fleet-mondtl-panel {
    flex: 100%;
    max-width: 100%;
  }

  .fleet-mondtl-obd-data {
    flex: 100%;
    max-width: 100%;
  }

  .report-dodetails-table {
    flex: 100%;
    max-width: 100%;
  }
}

.live-monitoring-maps-wrapper {
  width: 100%;
  height: auto;
  min-height: 500px;
}

.card-login {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  margin: 20px auto;
}

.custom-button-carbon {
  background-color: #fe0015 !important;
  color: #fff !important;
  width: 50% !important;
}

.custom-button-platform {
  background-color: #174060 !important;
  color: #fff !important;
  width: 50% !important;
}

.custom-button-stark {
  background-color: #1c406c !important;
  color: #fff !important;
  width: 50% !important;
}

.login-body {
  background-image: url("./assets/img/automa-login-bg.jpeg") !important;
  height: 100vh;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-body-carbon {
  background-image: url("./assets/img/telkomsel/bg-login-telkomsel.png") !important;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-body-stark {
  background-image: url("./assets/img/starkindustries/login-bg-stark-industries.jpg") !important;
  height: 100vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.automa-logo-login {
  position: absolute;
  height: auto;
  min-width: 150px;
  width: 165px;
  top: 1.8em;
  left: 2.5em;
}

.input-feedback {
  margin-top: -1em;
  color: red;
}

.login-page-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 9%;
  text-align: left;
  padding-left: 5px;
}

.login-page-footer-text {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-weight: bold;
  font-size: 8pt;
}

@media (max-width: 991.98px) {
  .app-header .navbar-brand {
    left: 20% !important;
  }
}

.sidebar .nav-dropdown-items {
  padding: 0;
  margin-left: 10px;
  /* Uncomment if navbar with icons is applied again */
  overflow-y: hidden;
  transition: max-height 0.3s ease-in-out;
}

@media (min-width: 768px) {
  .sidebar-minimized .sidebar .nav-dropdown-items .nav-item {
    width: 260px;
    margin-left: 15px;
    /* Used to be 20px when navbar with icons is applied */
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .sidebar-minimized .sidebar .nav-item:hover {
    width: 260px;
    overflow: visible;
  }

  .sidebar-minimized .sidebar .nav-link:hover {
    width: 260px;
    background: #20a8d8;
  }
}

@media (max-width: 576px) {
  .custom-button-carbon, .custom-button-platform, .custom-button-stark {
    width: 100% !important;
  }
}

@media (min-width: 1024px) {
  .sidebar-minimized .sidebar .nav-dropdown-items {
    margin-left: 0;
  }
  .sidebar-minimized .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items {
    position: absolute;
    left: 50px;
    display: inline;
    width: 210px;
  }
}

.sidebar {
  display: flex;
  flex-direction: column;
  padding: 0;
  color: #fff;
  background: #1a2631;
}

.sidebar-minimized .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items {
  background: #1a2631;
  color: #fff;
}

.sidebar .nav-link.active {
  color: #fff;
  background: #24323f;
}

.sidebar-minimized .sidebar .sidebar-minimizer::before {
  background-color: #151e27;
}

.Toastify__toast--error {
  font-size: 1.2vw;
  font-weight: 500;
  color: #fff;
  background-color: #d82b00 !important;
  border-radius: 8px !important;
}

.Toastify__toast--success {
  font-size: 1.2vw;
  font-weight: 500;
  color: #fff;
  background-color: #007e50 !important;
  border-radius: 8px !important;
}

.Toastify__toast--warning {
  font-size: 1.2vw;
  font-weight: 500;
  color: #fff;
  background-color: #d89b00 !important;
  border-radius: 8px !important;
}
